.App {
  background-color: #6a5acd; /* SlateBlue */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.floating-letters {
  font-size: 15vw; /* Adjust size based on viewport width */
  font-weight: bold;
  width: 66vw; /* Two-thirds of the viewport width */
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.floating-letter {
  display: inline-block;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.view-counter {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 1rem;
}
