.balloon-text {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 300px; /* Increased from 72px to 120px */
    color: #ff6b6b;
    text-shadow: 
      0 1px 0 #cc5454,
      0 2px 0 #cc5454,
      0 3px 0 #cc5454,
      0 4px 0 #cc5454,
      0 5px 0 #cc5454,
      0 6px 1px rgba(0,0,0,.1),
      0 0 5px rgba(0,0,0,.1),
      0 1px 3px rgba(0,0,0,.3),
      0 3px 5px rgba(0,0,0,.2),
      0 5px 10px rgba(0,0,0,.25),
      0 10px 10px rgba(0,0,0,.2),
      0 20px 20px rgba(0,0,0,.15);
    position: relative;
    display: inline-block;
    transition: transform 0.3s ease;
}

.balloon-text::before {
    content: attr(data-letter);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    color: rgba(0,0,0,0.2);
    text-shadow: none;
}

.balloon-text::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 30% 30%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%),
      radial-gradient(circle at 70% 70%, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%);
    z-index: 1;
    opacity: 0.7;
    pointer-events: none;
}

@keyframes float {
    0%, 100% { transform: translateY(0) rotate(-3deg); }
    50% { transform: translateY(-10px) rotate(3deg); }
}

.balloon-text {
    animation: float 3s ease-in-out infinite;
}

.balloon-text:hover {
    transform: scale(1.1);
}